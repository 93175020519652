<template>
  <LxpModal
    :show.sync="showModal"
    id="confirm-name-modal"
    centered
    title="Confirm your name"
    saveTitle="Confirm*"
  >
    <div class="confirm-name__content">
      <p>
        {{ $t("my_achievements.empty.confirm.name_verification_message") }}
      </p>
      <div class="confirm-name__form">
        <LxpInput
          :label="$t('my_profile.details.first_name')"
          :placeholder="$t('my_profile.details.first_name')"
          :value="verifyName.given_name"
          v-model="verifyName.given_name"
        />
        <LxpInput
          :label="$t('my_profile.details.last_name')"
          :placeholder="$t('my_profile.details.last_name')"
          :value="verifyName.family_name"
          v-model="verifyName.family_name"
        />
      </div>
    </div>
    <template #footer>
      <div class="confirm-name__footer">
        <div class="confirm-name__footer--btn">
          <LxpButton variant="outline-primary" @click="showModal = false">{{
            $t("general.cancel")
          }}</LxpButton>
          <LxpButton variant="primary" @click="onSave"
            >{{ $t("general.confirm") }}*</LxpButton
          >
        </div>
        <span class="confirm-name__footer--note">
          {{
            $t("my_achievements.empty.confirm.note_verification_message")
          }}</span
        >
      </div>
    </template>
  </LxpModal>
</template>
<script>
import { LxpButton, LxpModal, LxpInput } from "didactica";
import { mapGetters } from "vuex";
export default {
  emits: ["close"],
  components: {
    LxpModal,
    LxpInput,
    LxpButton
  },
  data() {
    return {
      showModal: true,
      verifyName: {
        given_name: "",
        family_name: ""
      }
    };
  },
  computed: {
    ...mapGetters(["allProfile"])
  },
  watch: {
    showModal(newValue) {
      if (newValue === false) {
        this.$emit("close");
      }
    }
  },
  mounted() {
    this.verifyName.given_name = this.allProfile.given_name;
    this.verifyName.family_name = this.allProfile.family_name;
  },
  methods: {
    onSave() {
      if (
        this.verifyName.given_name !== "" ||
        this.verifyName.family_name !== ""
      ) {
        this.$store.dispatch("updateProfile", this.verifyName).then(() => {
          this.showModal = false;
        });
      }
    }
  }
};
</script>
<style lang="scss">
#confirm-name-modal {
  .modal-header {
    padding: 1rem;
  }
  .modal-body {
    .confirm-name__content {
      p {
        @include body-regular;
        margin-bottom: 24px;
      }
      .confirm-name__form {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  .confirm-name__footer {
    .confirm-name__footer--btn {
      @include flex-horizontal-center;
    }
    .confirm-name__footer--note {
      @include label-medium;
      color: $brand-neutral-300;
      margin-top: 24px;
      display: flex;
    }
  }
}
</style>
